import React, { useEffect, useState } from "react";
import "../../components/FaqHome.scss";
import img from "../../assets/FAQ/listDoc.png";

function MicroCreditFaq() {
  const content = {
    "Le microcrédit particulier, comment ça marche ?":
      "Lorsque les critères de solvabilité imposés par les banques classiques ne permettent pas de répondre favorablement aux besoins d’un emprunteur, Notre partenaire Créa-Sol, en tant que Banque d’économie sociale et solidaire peut proposer un crédit « extra bancaire » ; un prêt solidaire qui prend en compte d’autres critères plus humains et se fonde sur un rapport de confiance. <br />Le microcrédit est un crédit qu’il convient de rembourser, ce n’est pas un don.",
    "Qui peut en bénéficier ?":
      "Les personnes qui :<br /><ul style='margin-left:50px; margin-right:50px; '> <li>n’ont pas accès à un prêt bancaire,</li>  <li>mais ont besoin d’argent pour financer un projet précis (mobilité, formation, logement, permis de conduire, etc.</li> <li>disposent d’une capacité de remboursement supportable.(Reste à vivre)</li> <li>et sont domiciliées en France Métropolitaine ou dans les DOM (non les TOM)</li></ul>",
    "Que peut financer le microcrédit ?":
      "Le microcrédit peut servir à financer :<br /><br /><ul style='margin-left:50px; margin-right:50px; '> <li>l’achat ou la réparation d’un véhicule,</li> <li>une formation professionnelle,</li> <li>le permis de conduire,</li> <li>des soins de santé non pris en charge par la sécurité sociale ou par la mutuelle,</li> <li>l’achat de meubles,</li> <li>l’achat d’électroménager,</li> <li>l’achat de matériels informatiques …</li></ul>",
    "Qu’est-ce que ne peut pas financer le microcrédit ?":
      "Le microcrédit ne peut pas servir à financer les dettes (loyer, électricité, gaz, découvert bancaire, crédits à la consommation …). ",
    "Conditions financières au 01/01/2024":
      "Les conditions financières de notre partenaire financier Créa Sol au 01/01/2024 sont les suivantes :<br /><br /><ul style='margin-left:50px; margin-right:50px; '> <li>Montant maximum 6 000€ (possibilité 8 000€ sous conditions)</li><li>Durée maximale du prêt 60 mois et jusqu’à 72 mois pour les prêts d’un montant supérieur à 5000€</li><li>Taux d’intérêt fixe à 5,90%, avec une contribution de solidarité de 4% du capital emprunté</li> </ul>",
    "Liste des pièces à fournir pour une demande de microcrédit": "IMG",
  };

  const [accordions, setAccordion] = useState([]);

  useEffect(() => {
    setAccordion(document.querySelectorAll(".accordion button"));
  }, []);

  const toggleAccordion = (e) => {
    setAccordion(document.querySelectorAll(".accordion button"));

    if (e.getAttribute("aria-expanded") === "false") {
      for (let i = 0; i < accordions.length; i++) {
        accordions[i].setAttribute("aria-expanded", "false");
      }
      e.setAttribute("aria-expanded", "true");
    } else {
      e.setAttribute("aria-expanded", "false");
    }
  };

  const faqFiller = () => {
    let htmlFaq = [];

    let conter = 0;
    for (const property in content) {
      conter++;
      if (content[property] === "IMG") {
        htmlFaq.push(
            <div className="accordion-item" key={conter}>
              <button
                id={`accordion-button-${conter}`}
                aria-expanded="false"
                onClick={(e) => toggleAccordion(e.currentTarget)}
              >
                <span className="accordion-title">{property}</span>
                <span className="icon" aria-hidden="true"></span>
              </button>
              <div className="accordion-content">
                <p><img src={img} alt="document liste"></img></p>
              </div>
            </div>
          );
      } else {
        htmlFaq.push(
          <div className="accordion-item" key={conter}>
            <button
              id={`accordion-button-${conter}`}
              aria-expanded="false"
              onClick={(e) => toggleAccordion(e.currentTarget)}
            >
              <span className="accordion-title">{property}</span>
              <span className="icon" aria-hidden="true"></span>
            </button>
            <div className="accordion-content">
              <p dangerouslySetInnerHTML={{ __html: content[property] }}></p>
            </div>
          </div>
        );
      }
    }
    return htmlFaq;
    //return htmlFaq;
  };

  return (
    <div className="container-faq">
      <div className="accordion">{faqFiller()}</div>
    </div>
  );
}

export default MicroCreditFaq;
