import NavBar from "../components/NavBar";
import "./GestionBudget.css";
import { HashLink as Link } from "react-router-hash-link";
import Faq from "../components/FAQ/FormationFaq";

function Formation() {
  return (
    <>
      <header>
        <NavBar name="Contact" />
      </header>
      <main>
        <div className="page-content">
          <br></br>
          <br></br>
          <div className="separation-nav-content"></div>
          <h1 className="title-gestion">
            <span>La Formation</span>
          </h1>
          <h2>- Pourquoi des actions de formations ?</h2>
          <p className="first-p-gestion">
            Ce volet formation / prévention, complète notre action traditionnelle
            de conseil et d’assistance aux personnes en difficulté financière.
            <br />
            <br />
            Dispenser des informations indispensables à la vie courante (
            argent, banque, postes budgétaire, crédits , consommation),
            Sensibiliser aux dérives possibles induites par des sollicitations
            permanentes, Identifier les problèmes et trouver des solutions
            pérennes, apparaissent comme les meilleures alliés pour éviter le
            surendettement ou le mal endettement.
          </p>
          <h2>- A qui s’adressent ces actions de formation ? </h2>
          <p className="first-p-gestion">
            S’agissant d’actions collectives, elles peuvent concerner les
            établissements scolaires (essentiellement secondaire et post BAC),
            les bénéficiaires d’actions sociales (CCAS, CMS, Mission locale,
            AFPA , …), les associations, les collectivités, le privé ...
          </p>
          <Link to="/contact" className="contact-button">
          Nous contacter
        </Link>
        <h1 className="title-welcome-home">F.A.Q. (Formation)</h1>
        <Faq />
        <div className="below-gestion"></div>
        </div>
      </main>
    </>
  );
}

export default Formation;
