import FooterNav from "../components/FooterNav";
import NavBar from "../components/NavBar";
import StackedCards from "../components/StackedCards";
import helpImage from "../assets/AidesPage/aides.jpg";
import "./Aides.css";

function Aides() {
  return (
    <>
      <NavBar name="Aides" />
      <br />

      <div className="bc-shade-parallax"></div>
      <div className="separation-nav-content"></div>
      <div className="img_gradient_aides_page">
        <h1 className="title-aide-top">Comment nous pouvons vous aider</h1>
        <img src={helpImage} alt="" className="image-aides-page-top"></img>
        <h1 className="title-aide-top-phone">
          Comment nous pouvons vous aider
        </h1>
      </div>
      <StackedCards />
      <FooterNav />
    </>
  );
}

export default Aides;
