import NavBar from "../components/NavBar";

function RGPD() {
  return (
    <>
      <header>
        <NavBar name="Contact" />
      </header>
      <main>
        <div className="page-content">
          <br></br>
          <br></br>
          <div className="separation-nav-content"></div>
          <h2>RGPD</h2>
          <p className="first-p-gestion">
            Dans le cadre de la loi du 20 juin 2018 relative aux données
            personnelles et du Règlement Général sur la Protection des Données
            (RGPD), les structures CRESUS ont mis en place une politique de
            données personnelles comprenant 10 engagements.
            <br />
            <br />
            Les informations recueillies sont enregistrées dans un fichier
            informatisé par CRESUS LORRAINE, association de droit local
            Alsace-Moselle, créée le 04 novembre 2003, ayant son <br/>siège social au
            3 bis rue d’Anjou, 57070 METZ, dont le numéro de <br />
            SIRET est 490 968 146 00038. <br />
            Téléphone : 03 87 57 72 10.
          </p>
          <h2>1. Protection</h2>
          <p className="first-p-gestion">
            Nous protégeons la vie privée de nos bénéficiaires. Parce que les
            données personnelles sont précieuses, CRESUS s’engage à appliquer
            la législation en vigueur.
          </p>
          <h2>2. Collecte</h2>
          <p className="first-p-gestion">
            Nous ne collectons les données de nos bénéficiaires qu’avec leur
            accord et en expliquant clairement l’usage que nous en faisons :
            <br />
            <br />
            <em>Formulaire de contact :</em> Nous collectons vos informations
            d’état civil, informations de contact. Les données collectées via le
            formulaire de contact sont utilisées pour le traitement de votre
            demande et pour la réalisation de statistiques. Vos données
            personnelles sont conservées pendant 2 années après le dernier
            contact.
            <br />
            <br />
            <em>Google Analytics /</em> Nous collectons des données de
            navigation, afin de mesurer l’audience de notre site internet. Ces
            données sont anonymisées.
            <br />
          </p>
          <h2>3. Communication</h2>
          <p className="first-p-gestion">
            Nous n’envoyons des communications et newsletters qu’avec
            l’autorisation explicite du destinataire de la communication.
          </p>
          <h2>4. Cookies</h2>
          <p className="first-p-gestion">
            Les Cookies que Crésus Lorraine émet ou peut émettre à partir du
            Site, permettent, selon votre choix de paramétrage de votre
            terminal que vous pouvez revoir à tout moment, de :
            <br /><br />
            <li>
              établir des statistiques et volumes de fréquentation et
              d’utilisation des divers éléments composant le Site (rubriques et
              contenus visités, parcours), permettant d’améliorer l’intérêt et
              l’ergonomie des services qui vous sont offerts ;
            </li>
            <br />
            <li>
              vous fournir des services et une navigation adaptés et ciblés.
            </li>
            Les cookies sont stockés pour une durée maximale de 13 mois. Si vous
            ne souhaitez pas que CRESUS LORRAINE puisse disposer de tout ou
            partie des Cookies cités ci-dessus, vous pouvez nous faire part de
            vos choix en paramétrant votre acceptation.
          </p>
          <h2>5. Hébergement</h2>
          <p className="first-p-gestion">Firebase (le formulaire n'est pas communiqué à Google)</p>
          <h2>6. Conservation</h2>
          <p className="first-p-gestion">
            Nous ne conservons les données personnelles que le strict temps
            nécessaire au traitement et au suivi du dossier et dans la limite de
            deux années à compter du dernier contact. Au-delà de ce délai, les
            données personnelles sont systématiquement anonymisées ou
            supprimées.
          </p>
          <h2>7. Partage d’information</h2>
          <p className="first-p-gestion">
            Nous ne partageons pas vos informations.
          </p>
          <h2> 8. Contact DPO</h2>
          <p className="first-p-gestion">
            Pour toutes questions ou commentaires relatifs à la Politique de
            Données Personnelles ou à la façon dont CRESUS LORRAINE procède à
            la collecte et à l’utilisation des données vous concernant, vous
            pouvez contacter CRESUS LORRAINE par voie postale ou électronique
            aux coordonnées ci-après :
            <br />
            <br />
            Crésus Lorraine 3bis rue d’Anjou, 57070 METZ
            <br />
            <br />
            cresus.lorraine@orange.fr
          </p>
          <h2>9. Vos droits</h2>
          <p className="first-p-gestion">
            Vous pouvez accéder aux données vous concernant, les rectifier,
            demander leur effacement ou exercer votre droit à la limitation du
            traitement de vos données. Vous pouvez également retirer à tout
            moment votre consentement, vous opposez au traitement de vos données
            ou solliciter la portabilité de vos données en contactant Crésus
            Lorraine.
          </p>
          <h2>10. Sécurité informatique</h2>
          <p className="first-p-gestion">
            Nous mettons tout en œuvre pour protéger l’ensemble de vos données
            personnelles par la sécurité de notre système informatique et la
            mise en place d’une procédure de gestion des violations de données à
            caractère personnel.
          </p>
          <h2>11. Procédure d’urgence</h2>
          <p className="first-p-gestion">
            En cas d’atteinte aux données personnelles, nous vous informerions
            le plus rapidement et plus précisément possible.
            <br />
            <br />
            Nous collectons les données en conformité avec le Règlement (UE)
            2016/679, la directive (UE) 2016/80 du 27 avril 2016 et les
            dispositions de la loi n°2004-801 du 6 août 2004 relative à la
            protection des personnes et modifiant la loi n° 78-17 du 6 janvier
            1978 relative à l’informatique, aux fichiers et aux libertés. Vos
            données ne seront traitées que dans le cadre du traitement de votre
            demande par CRESUS LORRAINE ou ses antennes locales.
            <br />
            <br />
            Je peux exercer mes droits relatifs aux données personnelles en
            contactant CRESUS LORRAINE à l’adresse suivante :
            cresus.lorraine@orange .fr
          </p>
          <h2>Mentions légales</h2>
          <p className="first-p-gestion">
            Les présentes mentions légales sont applicables au site Internet «
            cresus-lorraine ».
          </p>
          <h2>Coordonnées</h2>
          <p className="first-p-gestion">
            CRÉSUS LORRAINE est une association de droit local Alsace-Moselle,
            créée le 04 novembre 2003, ayant son siège social au 3 bis rue
            d’Anjou 57070 METZ dont le numéro de SIRET est , 490 968 146 00038
            ci-après dénommée « Crésus Lorraine ». Téléphone : 03 87 57 72 10{" "}
          </p>
          <h2>Modification des Mentions légales</h2>
          <p className="first-p-gestion">
            L’association CRESUS LORRAINE vous informe que les présentes
            mentions peuvent être modifiées à tout moment. Ces modifications
            sont publiées par leur mise en ligne.
            <br />
            <br />
            L’éditeur du Site www.cresus-lorraine.fr est l’association CRESUS
            LORRAINE dont Monsieur Pascal FANARA est le directeur de la
            publication, en sa qualité de Président. Le Site est hébergé par la
            société Google Cloud Platform Le Site est réalisé par Hugo Masson Pour toute question,
            nous vous remercions de bien vouloir envoyer un courriel à l’adresse
            suivante : cresus.lorraine@orange.fr
          </p>
          <h2>Litiges</h2>
          <p className="first-p-gestion">
            Les présentes mentions sont établies en conformité avec le droit
            français et notamment les dispositions de la loi n°2004-575 du 21
            juin 2004 pour la confiance dans l’économie numérique, la loi
            n°2004-801 du 6 août 2004 Informatique et Libertés et la loi n°
            2018-493 du 20 juin 2018 relative à la protection des données, qui
            adapte la loi du 6 janvier 1978 relative à l’informatique, aux
            fichiers et aux libertés au Règlement Général des Données
            Personnelles (RGPD) entré en vigueur le 25 mai 2018 (RGPD).
            <br />
            <br />
            Pour plus d’informations, vous pouvez consulter notre politique de
            confidentialité.
            <br />
            <br />
            Les juridictions françaises sont territorialement compétentes pour
            connaître de tout litige afférent au Site.
          </p>
        </div>
      </main>
    </>
  );
}

export default RGPD;
