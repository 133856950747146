import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Fragment } from "react";
import Home from "./pages/Home";
import Aides from "./pages/Aides";
import Contact from "./pages/Contact";
import Benevoles from "./pages/Benevoles";
import Ressources from "./pages/Ressources";
import GestionBudget from "./pages/GestionBudget";
import Surendettement from "./pages/Surendettement";
import MicroCredit from "./pages/MicroCredit";
import Formation from "./pages/Formation";
import RGPD from "./pages/RGPD";
// eslint-disable-next-line
import Test from "./pages/Test";
import NotFoundPage from "./pages/NotFoundPage";
import "./general.css";


import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyB8WGapVSBqQgGEhqUENmXDEo72AZaw8e4",
  authDomain: "cresus-website.firebaseapp.com",
  projectId: "cresus-website",
  storageBucket: "cresus-website.appspot.com",
  messagingSenderId: "586984648594",
  appId: "1:586984648594:web:76f4332df3b765feb2db4b",
  measurementId: "G-QEH9TZJE9E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


function App() {
  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/aides" element={<Aides />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/benevoles" element={<Benevoles />} />
          <Route path="/ressources" element={<Ressources />} />
          <Route path="/gestion-budget" element={<GestionBudget />} />
          <Route path="/surendettement" element={<Surendettement />} />
          <Route path="/micro-credit" element={<MicroCredit />} />
          <Route path="/formation" element={<Formation />} />
          <Route path="/RGPD" element={<RGPD />} />
          {/*<Route path="/test" element={<Test />} />*/}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
