import "./NavBarBig.css";
import { HashLink as Link } from "react-router-hash-link";

function NavBarBig() {
  return (
    <>
      <div className="test-box">
        <nav id="menu">
          <div className="menu-item">
            <div className="menu-text">
              <a href="/">Accueil &#x25BE;</a>
            </div>
            <div className="sub-menu">
              <div className="icon-box">
                <div className="icon">
                  <i className="fal fa-wind-turbine"></i>
                </div>
                <div className="text">
                  <div className="title">
                    Crésus Lorraine <i className="far fa-arrow-right"></i>
                  </div>
                  <a href="/" className="link-no-decoration">
                    <div className="sub-text">
                      Présentation de Crésus Lorraine.
                    </div>
                  </a>
                </div>
              </div>
              <div className="icon-box">
                <div className="icon">
                  <i className="fal fa-lightbulb-on"></i>
                </div>
                <div className="text">
                  <div className="title">
                    Nos actions <i className="far fa-arrow-right"></i>
                  </div>
                  <a href="/aides" className="link-no-decoration">
                    <div className="sub-text">Nos actions pour vous.</div>
                  </a>
                </div>
              </div>
              <div className="icon-box">
                <div className="icon">
                  <i className="fal fa-bomb"></i>
                </div>
                <div className="text">
                  <div className="title">
                    Fédération Crésus <i className="far fa-arrow-right"></i>
                  </div>
                  <a
                    href="https://www.cresus.org/"
                    className="link-no-decoration"
                  >
                    <div className="sub-text">
                      Voir le site de la Fédération Crésus.
                    </div>
                  </a>
                </div>
              </div>
              <div className="sub-menu-holder"></div>
            </div>
          </div>
          <div className="menu-item highlight">
            <div className="menu-text">
              <a href="/aides">Aides &#x25BE;</a>
            </div>
            <div className="sub-menu double">
              <div className="icon-box gb a">
                <div className="icon">
                  <i className="far fa-question-circle"></i>
                </div>
                <div className="text">
                  <a href="/gestion-budget" className="link-no-decoration">
                    <div className="title">
                      Gestion budgétaire
                    </div>
                    <div className="sub-text">Conseils en gestion budgétaire</div>
                  </a>
                </div>
              </div>
              <div className="icon-box gb b">
                <div className="icon">
                  <i className="far fa-users-class"></i>
                </div>
                <div className="text">
                  <a href="/surendettement" className="link-no-decoration">
                    <div className="title">
                      Surendettement
                    </div>
                    <div className="sub-text">Accompagnement surendettement</div>
                  </a>
                </div>
              </div>
              <div className="icon-box gb c">
                <div className="icon">
                  <i className="far fa-school"></i>
                </div>
                <div className="text">
                  <a href="/micro-credit" className="link-no-decoration">
                    <div className="title">
                      Micro crédit
                    </div>
                    <div className="sub-text">
                      Aide à la mise en place d'un microcrédit
                    </div>
                    </a>
                </div>
              </div>

              <div className="icon-box gb c">
                <div className="icon">
                  <i className="far fa-school"></i>
                </div>
                <div className="text">
                  <a href="/formation" className="link-no-decoration">
                    <div className="title">
                      Formations
                    </div>
                    <div className="sub-text">
                      Actions de formation
                    </div>
                    </a>
                </div>
              </div>

              <div className="bottom-container">
                Prendre un rendez-vous <a href="/contact">Contactez nous</a>
              </div>
              <div className="sub-menu-holder"></div>
            </div>
          </div>

          <div className="menu-item">
            <div className="menu-text">
              <a href="/contact">Contact &#x25BE;</a>
            </div>
            <div className="sub-menu">
              <div className="icon-box">
                <div className="icon">
                  <i className="far fa-satellite"></i>
                </div>
                <div className="text">
                  <Link to="/contact#map" className="link-no-decoration">
                    <div className="title">
                      Localisations
                    </div>
                    <div className="sub-text">Carte des permanences.</div>
                    </Link>
                </div>
              </div>
              <div className="icon-box">
                <div className="icon">
                  <i className="fab fa-twitter-square"></i>
                </div>
                <div className="text">
                  <Link to="/contact#form" className="link-no-decoration">
                    <div className="title">
                      Formulaire
                    </div>
                    <div className="sub-text">Formulaire de contact.</div>
                  </Link>
                </div>
              </div>
              <div className="icon-box">
                <div className="icon">
                  <i className="fab fa-twitter-square"></i>
                </div>
                <Link to="/RGPD">
                  <div className="text">
                    <div className="title">
                      Données
                    </div>
                    <div className="sub-text">Mentions légales et RGPD</div>
                  </div>
                </Link>
              </div>
              <div className="sub-menu-holder"></div>
            </div>
          </div>
          <div className="menu-item highlight">
            <div className="menu-text">
              <a href="/ressources">Infos &#x25BE;</a>
            </div>
            <div className="sub-menu triple">
              <div className="top-container gb c icon-box">
                <div className="icon big">
                  <i className="far fa-book"></i>
                </div>
                <Link to="/contact#form">
                  <div className="text">
                    <div className="title">Par quoi commencer</div>
                    <div className="sub-text">Peut-être en nous écrivant ?</div>
                  </div>
                </Link>
              </div>
              <div className="box">
                <h3>Liens utiles</h3>
                <a href="https://www.cresus.org/">Fédération Crésus</a>
                <a href="https://caf.fr/">CAF</a>
                <a href="https://www.service-public.fr/">Service Public</a>
                <a href="https://www.mesquestionsdargent.fr/">
                  Mes questions d'argent
                </a>
              </div>
              <div className="icon-box flat">
                <div className="icon">
                  <i className="fal fa-plug"></i>
                </div>
              </div>
              <div className="icon-box flat">
                <div className="icon">
                  <i className="fal fa-comments"></i>
                </div>
                <div className="text">
                <Link to="/RGPD">
                  <div className="title">
                    RGPD <i className="far fa-arrow-right"></i>
                  </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div id="sub-menu-container">
            <div id="sub-menu-holder">
              <div id="sub-menu-bottom"></div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default NavBarBig;
