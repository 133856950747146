import NavBar from "../components/NavBar";
import { HashLink as Link } from "react-router-hash-link";
import Faq from "../components/FAQ/MicroCreditFaq";

function MicroCredit() {
  return (
    <>
      <NavBar name="Aides" />
      <div className="page-content">
        <div className="separation"></div>
        <h1 className="title-gestion">
          Accompagnement à la mise en place d’un microcrédit
        </h1>
        <h2>- Le microcédit</h2>
        <p className="first-p-gestion">
          Le microcrédit consiste à prêter de petites sommes d’argent à des
          personnes ayant des difficultés d’accès au crédit bancaire classique,
          du fait d’une insuffisance de revenus ou d’une situation de précarité
          sociale (exemple : chômeurs, bénéficiaires des minima sociaux…).
          <br />
          <br /> 
          Le microcrédit est octroyé pour réaliser un projet personnel en
          lien avec l’insertion ou la réinsertion sociale, sous réserve que le
          bénéficiaire ait, malgré ses difficultés, une capacité de
          remboursement suffisante. Il ne sert pas à payer des dettes et ce
          n’est pas un don.
        </p>
        <p className="first-p-gestion">
        Les projets de financement concernent par exemple :<br />
          <br />
          <li>
          -	la mobilité (achat ou réparation véhicule, permis de conduire),
          </li>
          <li>
          -	la formation (stage, équipement informatique…),
          </li>
          <li>
          -	l’accès au logement (dépôt de garantie, équipement ménager et mobilier de première nécessité…),
          </li>
          <li>
          -	frais de santé
          </li>
        </p>
        <Link to="/contact" className="contact-button">
          Nous contacter
        </Link>
        <h1 className="title-welcome-home">F.A.Q. (Microcédit)</h1>
        <Faq />
        <div className="below-gestion"></div>
      </div>
    </>
  );
}

export default MicroCredit;
