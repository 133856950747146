import "./FooterNav.css";

function FooterNav() { 

    return (
        <footer >
            <div className="footer-regular-centent">
                
            </div>
        </footer>
    )
}

export default FooterNav;