import NavBar from "../components/NavBar";
import { HashLink as Link } from "react-router-hash-link";
import Faq from "../components/FAQ/GestionFaq";
import "./GestionBudget.css";

function GestionBudget() {
  return (
    <>
      <NavBar name="Aides" />
      <div className="page-content">
        <div className="separation"></div>
        
          <h1 className="title-gestion">
            <span>Accompagnement à la gestion Budgétaire</span>
          </h1>
        <div className="div-gestion-title">
        <a
          href="https://solidarites-sante.gouv.fr/affaires-sociales/lutte-contre-l-exclusion/PCB"
          alt=""
          target="blank"
        >
          <img
            src={require(`../assets/AidesPage/GestionBudget/logo.png`)}
            alt=""
            className="logo-top-gestion"
          ></img>
          <img
            src={require(`../assets/AidesPage/GestionBudget/point.jpg`)}
            alt=""
            className="logo-top-gestion"
          ></img>
        </a></div>

        <h2>- Qu'est-ce que l'accompagnement budgétaire ?</h2>
        <p className="first-p-gestion">
          L'accompagnement budgétaire par Crésus Lorraine vise à vous soutenir
          dans le rétablissement de l'équilibre de votre budget.
        </p>
        <h2>- Qu'est-ce qu'un Point Conseil Budget (PCB) ?</h2>
        <p className="first-p-gestion">
          Le Point Conseil Budget est une mesure clé de la stratégie nationale
          de prévention et de lutte contre la pauvreté. C’est un service gratuit
          labellisé par l’État.
          <br />
          <br />
          Il permet la prévention du surendettement et le renfort de
          l’accompagnement des personnes en difficultés financières.
          <br />
          <br />
          Les Points Conseil Budget (PCB) sont des structures d’accueil pour
          toute personne ayant besoin d’un accompagnement à la gestion
          budgétaire.
        </p>
        <Link to="/contact" className="contact-button">
          Nous contacter
        </Link>
        <h1 className="title-welcome-home">F.A.Q. (Gestion Budgétaire)</h1>
        <Faq />
        <div className="below-gestion"></div>
      </div>
    </>
  );
}

export default GestionBudget;
