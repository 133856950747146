import React, { useEffect, useState } from "react";
import "../../components/FaqHome.scss";

function FormationFaq() {
  const content = {
    "Point conseil budget : De quoi s’agit-il ?":
      "<ol><li>Un Point conseil budget est un lieu d’accueil ouvert à tous, où des conseillers spécialisés sont disponibles, sur rendez-vous, pour vous aider à mieux gérer votre budget.</li> <li>Leurs conseils sont confidentiels et gratuits.<li>Un accompagnement budgétaire individuel peut vous être proposé. </li> </ol>",
    "Vous pouvez être intéressé(e) si :":
      "<ul><li>Vous souhaitez faire le point sur votre situation financière</li> <li>Vous faites face à une situation financière difficile : Baisse de vos revenus, augmentation de vos charges</li> <li>Vous avez besoin d’être conseillé(e) pour mieux gérer votre budget</li> <li>Vous anticipez un changement de situation personnelle ou professionnelle</li> <li>Vous avez des difficultés à payer vos factures ou à rembourser vos dettes</li> </ul>",
  };

  const [accordions, setAccordion] = useState([]);

  useEffect(() => {
    setAccordion(document.querySelectorAll(".accordion button"));
  }, []);

  const toggleAccordion = (e) => {
    setAccordion(document.querySelectorAll(".accordion button"));

    if (e.getAttribute("aria-expanded") === "false") {
      for (let i = 0; i < accordions.length; i++) {
        accordions[i].setAttribute("aria-expanded", "false");
      }
      e.setAttribute("aria-expanded", "true");
    } else {
      e.setAttribute("aria-expanded", "false");
    }
  };

  const faqFiller = () => {
    let htmlFaq = [];

    let conter = 0;
    for (const property in content) {
      conter++;
      htmlFaq.push (
        <div className="accordion-item" key={conter}>
          <button
            id={`accordion-button-${conter}`}
            aria-expanded="false"
            onClick={(e) => toggleAccordion(e.currentTarget)}
          >
            <span className="accordion-title">{property}</span>
            <span className="icon" aria-hidden="true"></span>
          </button>
          <div className="accordion-content">
            <p dangerouslySetInnerHTML={{ __html: content[property] }}></p>
          </div>
        </div>
      );
    }
    return htmlFaq;
    //return htmlFaq;
  };

  return (
    <div className="container-faq">
      <div className="accordion">
       
          
        {faqFiller()}
      </div>
    </div>
  );
}

export default FormationFaq;
