import NavBar from "../components/NavBar";
import CardLien from "../components/CardLienUtile.js";
import "./Ressources.css";

function Ressources() {
  return (
    <>
      <NavBar name="Ressources" />
      <div className="page-content">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1 className="ressource-title">Liens Utiles</h1>
        <div className="ressource-cards" id="lien-utils">
        <CardLien img="cresus" lien="https://www.cresus.org/" />
        <CardLien img="caf" lien="https://www.caf.fr/" />
        <CardLien img="servicepublic" lien="https://www.service-public.fr/" />
        <CardLien img="mediation" lien="https://www.mediation-assurance.org/les-cahiers-de-la-mediation/" />
        <CardLien
          img="mesquestionsdargent"
          lien="https://www.mesquestionsdargent.fr/"
        /></div>
        <h1 className="ressource-title">Partenaires</h1>
        <div className="ressource-cards" id="partenaires">
          <CardLien
            img="cohesionTerr"
            lien="https://www.cohesion-territoires.gouv.fr/"
          />
          <CardLien
            img="pointJustice"
            lien="https://www.cdad-moselle.justice.fr/"
          />
          <CardLien img="metz" lien="https://metz.fr/" />
          <CardLien img="thionville" lien="https://thionville.fr/fr" />

          <CardLien img="montigny" lien="http://www.montigny-les-metz.fr/" />
          <CardLien img="phalsbourg" lien="https://www.phalsbourg.fr/" />
          <CardLien img="moselle" lien="https://www.moselle.fr/" />
          <CardLien
            img="caissedesdepots"
            lien="https://www.caissedesdepots.fr/"
          />
          <CardLien img="drulingen" lien="https://www.drulingen.fr/" />
          <CardLien
            img="danneetquatrevents"
            lien="https://danneetquatrevents.fr/fr/"
          />
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
}

export default Ressources;
