import NavBar from "../components/NavBar";

function Benevoles() {
  return (
    <>
      <NavBar name="Bénévoles" />
      <br />
      <div className="pageUnderNavBar"></div>
    </>
  );
}

export default Benevoles;