import NavBar from "../components/NavBar";
import { HashLink as Link } from "react-router-hash-link";
import Faq from "../components/FAQ/SurendettementFaq";
import "./GestionBudget.css";

function Surendettement() {
  return (
    <>
      <NavBar name="Aides" />
      <div className="page-content">
        <div className="separation"></div>
        <h1 className="title-gestion">Accompagnement surendettement</h1>
        <h2>- Etes-vous surendetté ?</h2>
        <p className="first-p-gestion">
          Si vous n'arrivez plus, malgré vos efforts, à payer vos dettes
          courantes comme par exemple le loyer, les factures d’énergie, d’eau ou
          encore les mensualités d’un crédit, c’est que vous êtes en situation
          de surendettement. Dans ce cas, vous pouvez avoir recours à la
          procédure de surendettement.
        </p>
        <h2>- Qui peut déposer un dossier de surendettement ?</h2>
        <p className="first-p-gestion">
          La procédure de traitement du surendettement est un service public,
          mis en œuvre par la Banque de France.
          <br />
          <br />
          Elle est gratuite et s’adresse aux particuliers qui malgré leurs
          efforts, n’arrivent plus à faire face à leurs dettes (dettes de
          crédit, dettes de loyers…).
          <br />
          <br />
          Pour en bénéficier, il faut habiter en France ou pour les Français
          habitant à l’étranger, être endetté auprès de créanciers en France. Il
          faut être de bonne foi, c’est-à-dire notamment, ne pas s’être
          surendetté volontairement.
        </p>
        <h2>
          <em>PRECISION</em>
        </h2>
        <p className="first-p-gestion">
          <em>
            Si vous êtes, artisan, commerçant, agriculteur ou une personne
            exerçant une profession indépendante, vous ne pouvez pas déposer de
            dossier de surendettement pour des dettes professionnelles: en
            effet, dans cette situation, vous relevez des procédures collectives
            régies par le Code du Commerce.
          </em>
          <br />
          <a href="https://www.service-public.fr/" target="blank">
            En savoir +
          </a>
        </p>
        <h2>- Où déposer un dossier de surendettement ?</h2>
        <p className="first-p-gestion">
          <li>
            Vous pouvez le faire vous-même en allant sur le{" "}
            <a href="https://particuliers.banque-france.fr/" target="blank">
              site internet de Banque de France | Particuliers 
            </a>
            .
          </li>

          <li>
            Nous pouvons aussi le faire ensemble dans{" "}
            <Link to="/contact#map">nos permanences Crésus Lorraine</Link>.
          </li>
        </p>
        <Link to="/contact" className="contact-button">
          Nous contacter
        </Link>
        <br />
        <br />
        <br />
        <br />
        <h2>
          - Que dois-je faire une fois mon dossier de surendettement déposé ?
        </h2>
        <p className="first-p-gestion">
          Il est important que vous respectiez les points ci dessous :<br />
          <br />
          <li>
            Dans l'attente de la décision de recevabilité vous devrez continuer
            à régler vos dettes dans la mesure de vos possibilités.
          </li>
          <li>
            Vous ne devez pas aggraver votre endettement en souscrivant de
            nouveaux crédits, ni vendre certains de vos biens au profit d'un
            créancier.
          </li>
          <li>
            Tout changement dans votre situation doit être signalé à la
            commission le plus rapidement possible (changement d'adresse,
            travail...).
          </li>
          <li>
            Prenez connaissance de tous les courriers relatifs au traitement de
            votre dossier, en particulier les courriers en recommandé avec
            accusé de réception. Ceci est très important, afin que la commission
            puisse traiter votre dossier.
          </li>
        </p>
        <p className="blue">
          <em>
            <u>A noter</u> : dès le dépôt de votre dossier et durant toute la
            procédure, vous êtes inscrit au Fichier national des incidents de
            remboursement des Crédits aux Particuliers (FICP) qui peut être
            consulté par tous les établissements accordant des crédits.
          </em>
        </p>
        <h1 className="title-welcome-home">F.A.Q. (Surendettement)</h1>
        <Faq />
        <div className="below-gestion"></div>
      </div>
    </>
  );
}

export default Surendettement;
