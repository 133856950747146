import "./CardLienUtile.css";

function CardLienUtile(props) {

    const link = props.lien;

  return (
    <>
    <a href={link} target="blank">
        <div className="card-lien">
                <img className="image-card-lien" src={require(`../assets/Partenaires/${props.img}.png`)} alt="Cohesion Territoire"></img>
        </div>
    </a>
    </>
  );
}

export default CardLienUtile;