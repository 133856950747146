import NavBar from "../components/NavBar";
import "./Home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { faHandBackFist } from "@fortawesome/free-solid-svg-icons";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import PCB from "./../assets/files/Presentation_PCB_2022.pptx";
import FooterNav from "../components/FooterNav";

function Home() {
  const NBR_IMG = 3;
  const randomImageNbr = Math.floor(Math.random() * NBR_IMG) + 1;

  return (
    <div className="bg">
      <NavBar name="Accueil" />
      <div className="page-content">

      <div className="row-home">
          <div className="column-home">
            <img
              src={require(`../assets/HomePage/cresusLogoOnly.png`)}
              alt=""
              className="home-logo two"
            ></img>
          </div>
          <div className="column-home">
            <img
              src={require(`../assets/HomePage/point.png`)}
              alt=""
              className="home-logo one"
            ></img>
          </div>
        </div>

        {/*<div className="separation-nav-content"></div>*/}<img
          src={require(`../assets/HomePage/${randomImageNbr}.jpg`)}
          alt=""
          className="image-home-page-top"
        ></img>

        

        <h1 className="title-welcome-home f">
          Bienvenue sur le site de Crésus Lorraine
        </h1>

        <p className="paragraph-home">
          CRESUS LORRAINE (Chambre Régionale de Surendettement Social de
          Lorraine) est une association loi 1908 d'aide aux personnes en
          situation de difficulté financière, de surendettement ou d'exclusion
          bancaire, avec accompagnement budgétaire. CRESUS LORRAINE est membre
          de la Fédération des associations CRESUS, reconnue d'utilité publique.
          CRESUS LORRAINE vous assure des entretiens confidentiels sur
          rendez-vous. CRÉSUS LORRAINE est labellisée Point Conseil Budget. Les
          Points conseil budget (PCB) sont des structures d'accueil
          inconditionnel mises en place sous l'égide du Ministère des
          Solidarités et de la Santé destinées à accompagner toute personne
          rencontrant des difficultés budgétaires et ayant besoin d'un
          accompagnement. Les objectifs des PCB sont de prévenir le
          surendettement et de favoriser l'éducation budgétaire.
        </p>
        <h1 className="title-welcome-home">Nos missions</h1>
        {/* CARDS */}
        <div className="cards" id="cards">
          <div className="card card-1">
            <div className="card__icon">
              <FontAwesomeIcon icon={faCalculator} />
            </div>
            <h2 className="card__title">
              Accompagnement à la gestion Budgétaire.
            </h2>
            <p className="card__apply">
              <a className="card__link" href="/gestion-budget">
                Allez voir <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </p>
          </div>
          <div className="card card-1">
            <div className="card__icon">
              <FontAwesomeIcon icon={faQuestion} />
            </div>
            <h2 className="card__title">Accompagnement surendettement.</h2>
            <p className="card__apply">
              <a className="card__link" href="/surendettement">
                Allez voir <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </p>
          </div>
          <div className="card card-1">
            <div className="card__icon">
              <FontAwesomeIcon icon={faHandBackFist} />
            </div>
            <h2 className="card__title">
              Accompagnement à la mise en place d'un microcredit
            </h2>
            <p className="card__apply">
              <a className="card__link" href="/micro-credit">
                Allez voir <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </p>
          </div>
          <div className="card card-1">
            <div className="card__icon">
              <FontAwesomeIcon icon={faList} />
            </div>
            <h2 className="card__title">Actions de formation</h2>
            <p className="card__apply">
              <a className="card__link" href="/formation">
                Allez voir <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </p>
          </div>

          <div className="card card-1">
            <div className="card__icon">
              <FontAwesomeIcon icon={faDownload} />
            </div>
            <h2 className="card__title">Nos actions</h2>
            <p className="card__apply">
              <a className="card__link" href={PCB} download>
                Télécharger (2Mo)
              </a>
            </p>
          </div>
          <div className="card pl">

          </div>
        </div>
        {/*<h1 className="title-welcome-home">F.A.Q. (Foire Aux Questions)</h1>
          <FaqHome />*/}
      </div>

      <FooterNav />
    </div>
  );
}

export default Home;
