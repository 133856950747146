import React, { useRef, useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import NavBar from "../components/NavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faList } from "@fortawesome/free-solid-svg-icons";
import "../components/Contact.css";
import "../components/Select.scss";
import "../components/listPermaContact.scss";
import FooterNav from "../components/FooterNav";
import contactImage from "../assets/AidesPage/aides.jpg";
import contactImageComputer from "../assets/contactPage/contact_us_computer.jpg";

function Contact() {
  const mapHtml = (
    <div className="map_contact">
      <iframe id="map"
        className="iframe_map_contact"
        title="map" src="https://www.google.com/maps/d/u/2/embed?mid=1E6oz7ewN3SaXR9WmMfeokDRlKh3KZtc&ehbc=2E312F" width="90%"
        height="480"></iframe>
    </div>
  );

  const [toDisplay, setToDisplay] = useState(mapHtml);

  useEffect(() => {
    document.title = "Contactez Nous";
    document.getElementsByTagName("META")[2].content =
      "Contacter une de nos permanence en Lorraine";
  });

  const form = useRef();

  const sendEmail = (e) => {
    if (true) {
      // CHNAGE FOR IF SUBJECT IS DEFINED
      e.preventDefault();

      emailjs
        .sendForm(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          form.current,
          process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
        .then(
          (result) => {
            console.log(result.text + " Message sent");
          },
          (error) => {
            console.log(error.text);
          }
        );
      e.target.reset();
    }
  };

  function displayMap() {
    setToDisplay(mapHtml);
  }

  function displayList() {
    setToDisplay(
      <>
        <div className="container-contact">
          <div className="table-contact">
            <div className="table-header-contact">
              <div className="header__item-contact">
                <span id="name" class="filter__link">
                  Permanences
                </span>
              </div>
              <div className="header__item-contact">
                <span id="wins" class="filter__link filter__link--number">
                  Addresse
                </span>
              </div>
              <div className="header__item-contact">
                <span id="draws" class="filter__link filter__link--number">
                  Horaires
                </span>
              </div>
              <div className="header__item-contact">
                <span id="losses" class="filter__link filter__link--number">
                  Prise Rendez-vous
                </span>
              </div>
              <div className="header__item-contact">
                <span id="total" class="filter__link filter__link--number">
                  Tél. RDV
                </span>
              </div>
            </div>
            <div className="table-content-contact">
              <div className="table-row-contact">
                <div className="table-data-contact">THIONVILLE</div>

                <div className="table-data-contact">
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=CCAS%20Bld%20Jeanne%20d'Arc%2057100%20Thionville"
                    target="blank"
                  >
                    CCAS Bld Jeanne d'Arc 57100 Thionville
                  </a>
                </div>

                <div className="table-data-contact">lundi 9h à 12h</div>
                <div className="table-data-contact">aux heures de bureau</div>
                <div className="table-data-contact">06 06 92 36 73</div>
              </div>
              <div className="table-row-contact">
                <div className="table-data-contact">MONTIGNY les METZ</div>

                <div className="table-data-contact">
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=Mairie%2039%20rue%20Kennedy%2057957%20Montigny%20les%20Metz"
                    target="blank"
                  >
                    Mairie de quartier 39 rue Kennedy 57957 Montigny les Metz
                  </a>
                </div>

                <div className="table-data-contact">
                  lundi 9h à 12h (un lundi sur 2)
                </div>
                <div className="table-data-contact">aux heures de bureau</div>
                <div className="table-data-contact">03 87 57 41 60</div>
              </div>
              <div className="table-row-contact">
                <div className="table-data-contact">MONTIGNY les METZ</div>

                <div className="table-data-contact">
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=Mairie%2039%20rue%20Kennedy%2057957%20Montigny%20les%20Metz"
                    target="blank"
                  >
                    Mairie de quartier 39 rue Kennedy 57957 Montigny les Metz
                  </a>
                </div>

                <div className="table-data-contact">mercredi 14 h à 16h</div>
                <div className="table-data-contact">aux heures de bureau</div>
                <div className="table-data-contact">03 87 57 41 60</div>
              </div>
              <div className="table-row-contact">
                <div className="table-data-contact">CREUTZWALD</div>

                <div className="table-data-contact">
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=12%20rue%20Cochois%2057130%20Creutzwald"
                    target="blank"
                  >
                    Antenne de Justice 12 rue Cochois 57130 Creutzwald
                  </a>
                </div>

                <div className="table-data-contact">mercredi 9h à 12h</div>
                <div className="table-data-contact">aux heures de bureau</div>
                <div className="table-data-contact">06 08 21 43 08</div>
              </div>
              <div className="table-row-contact">
                <div className="table-data-contact">FORBACH</div>

                <div className="table-data-contact">
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=2%20Place%20Robert%20Schuman%2057600%20Forbach"
                    target="blank"
                  >
                    2 Place. Robert Schuman
                  </a>
                </div>

                <div className="table-data-contact">Permanence le mercredi</div>
                <div className="table-data-contact">aux heures de bureau</div>
                <div className="table-data-contact">03 87 88 66 66</div>
              </div>
              {/* 
              <div className="table-row-contact">
                <div className="table-data-contact">FORBACH</div>

                <div className="table-data-contact">
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=2%20av%20de%20l'Europe%2057600%20Forbach"
                    target="blank"
                  >
                    MJD 2 av.de l'Europe 57600 Forbach
                  </a>
                </div>

                <div className="table-data-contact">lundi toute la journée</div>
                <div className="table-data-contact">aux heures de bureau</div>
                <div className="table-data-contact">03 87 88 66 66</div>
              </div>
              <div className="table-row-contact">
                <div className="table-data-contact">FORBACH</div>

                <div className="table-data-contact">
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=2%20av%20de%20l'Europe%2057600%20Forbach"
                    target="blank"
                  >
                    MJD 2 av.de l'Europe 57600 Forbach
                  </a>
                </div>

                <div className="table-data-contact">
                  mercredi toute la journée
                </div>
                <div className="table-data-contact">aux heures de bureau</div>
                <div className="table-data-contact">03 87 88 66 66</div>
              </div> */}
              <div className="table-row-contact">
                <div className="table-data-contact">BOUZONVILLE</div>

                <div className="table-data-contact">
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=4 av de la gare 57320 Bouzonville"
                    target="blank"
                  >
                    4 av. de la gare 57320 Bouzonville
                  </a>
                </div>

                <div className="table-data-contact">vendredi 14h à 16h</div>
                <div className="table-data-contact">
                  lundi au vendredi 10h à 12h
                </div>
                <div className="table-data-contact">06 87 51 45 92</div>
              </div>
              <div className="table-row-contact">
                <div className="table-data-contact">HAYANGE</div>

                <div className="table-data-contact">
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=10 rue Wendel 57700 Hayange"
                    target="blank"
                  >
                    MJD 10 rue Wendel 57700 Hayange
                  </a>
                </div>

                <div className="table-data-contact">lundi toute la journée</div>
                <div className="table-data-contact">aux heures de bureau</div>
                <div className="table-data-contact">03 82 51 98 62</div>
              </div>
              <div className="table-row-contact">
                <div className="table-data-contact">SARREBOURG</div>

                <div className="table-data-contact">
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=1 av Clémenceau sarrebourg"
                    target="blank"
                  >
                    1 av.Clémenceau bâtiment Schweitzer
                  </a>
                </div>

                <div className="table-data-contact">vendredi 09h à 12h</div>
                <div className="table-data-contact">aux heures de bureau</div>
                <div className="table-data-contact">06 06 84 26 06</div>
              </div>
              <div className="table-row-contact">
                <div className="table-data-contact">PHALSBOURG</div>

                <div className="table-data-contact">
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=17 rue commandant Taillant 57540"
                    target="blank"
                  >
                    Maison de services Lobau 17 rue commandant Taillant 57540 Phalsbourg
                  </a>
                 
                </div>

                <div className="table-data-contact">
                  lundi après midi 14h à 16h
                </div>
                <div className="table-data-contact">sur répondeur</div>
                <div className="table-data-contact">03 87 24 94 11</div>
              </div>
              <div className="table-row-contact">
                <div className="table-data-contact">METZ Borny</div>

                <div className="table-data-contact">
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=Pôle des Lauriers 3 bis rue d'Anjou 57070 Metz"
                    target="blank"
                  >
                    Pôle des Lauriers 3 bis rue d'Anjou 57070 Metz
                  </a>
                </div>

                <div className="table-data-contact">mercredi 9h à 12h</div>
                <div className="table-data-contact">aux heures de bureau</div>
                <div className="table-data-contact">03 87 55 55 98</div>
              </div>
              <div className="table-row-contact">
                <div className="table-data-contact">MORHANGE</div>

                <div className="table-data-contact">
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=Hotel Communautaire 2 rue Pratel 57340 Morhange"
                    target="blank"
                  >
                    Hotel Communautaire 2 rue Pratel 57340 Morhange
                  </a>
                </div>

                <div className="table-data-contact">
                  mercredi après midi 14h à 16h
                </div>
                <div className="table-data-contact">aux heures de bureau</div>
                <div className="table-data-contact">06 33 20 39 21</div>
              </div>
              <div className="table-row-contact">
                <div className="table-data-contact">SAINT AVOLD</div>

                <div className="table-data-contact">
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=2 rue du Lac 57500 Saint Avold"
                    target="blank"
                  >
                    CCAS 2 rue du Lac 57500 Saint Avold
                  </a>
                </div>

                <div className="table-data-contact">
                  vendredi toute la journée
                </div>
                <div className="table-data-contact">aux heures de bureau</div>
                <div className="table-data-contact">03 87 91 29 60</div>
              </div>
              <div className="table-row-contact">
                <div className="table-data-contact">WOIPPY</div>

                <div className="table-data-contact">
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=6 place Jean Perrin 57140 Woippy"
                    target="blank"
                  >
                    MJD 6 place Jean Perrin 57140 Woippy
                  </a>
                </div>

                <div className="table-data-contact">jeudi 9h à 12h</div>
                <div className="table-data-contact">aux heures de bureau</div>
                <div className="table-data-contact">03 87 18 50 67</div>
              </div>
              <div className="table-row-contact">
                <div className="table-data-contact">CARLING</div>

                <div className="table-data-contact">
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=L'hélice d'or 3 rue de la Fontaine 57490 Carling"
                    target="blank"
                  >
                    L'hélice d'or 3 rue de la Fontaine 57490 Carling
                  </a>
                </div>

                <div className="table-data-contact">/</div>
                <div className="table-data-contact">aux heures de bureau</div>
                <div className="table-data-contact">06 08 21 43 08</div>
              </div>
              <div className="table-row-contact">
                <div className="table-data-contact">CHATEAU SALINS</div>
                <div className="table-data-contact">/</div>
                <div className="table-data-contact">sur rendez vous</div>
                <div className="table-data-contact">aux heures de bureau</div>
                <div className="table-data-contact">06 33 20 39 21</div>
              </div>
              <div className="table-row-contact">
                <div className="table-data-contact">DIEUZE</div>
                <div className="table-data-contact">/</div>
                <div className="table-data-contact">sur rendez vous</div>
                <div className="table-data-contact">aux heures de bureau</div>
                <div className="table-data-contact">06 33 20 39 21</div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <header>
        <NavBar name="Contact" />
      </header>
      <main>
        <div className="separation-nav-content"></div>
        <div className="pageUnderNavBar">
          <div className="bc-shade-parallax"></div>
          <div className="img_gradient_aides_page">
            <h1 className="title-aide-top">Venez nous voir</h1>
            <img
              src={contactImage}
              alt=""
              className="image-aides-page-top"
            ></img>
            <h1 className="title-aide-top-phone">Venez nous voir</h1>
          </div>
          <article>
            <button onClick={displayMap} className="button-map-list one">
              <FontAwesomeIcon icon={faMapLocationDot} />
            </button>
            <button onClick={displayList} className="button-map-list two">
              <FontAwesomeIcon icon={faList} />
            </button>

            {toDisplay}
          </article>
          <article>
            <div className="bc-shade-parallax"></div>
            <div className="img_gradient_aides_page">
              <h1 className="title-aide-top">Ou contactez nous</h1>
              <img
                src={contactImageComputer}
                alt=""
                className="image-aides-page-top"
              ></img>
              <h1 className="title-aide-top-phone">Ou écrivez nous</h1>
            </div>
            <form ref={form} onSubmit={sendEmail} className="form_contact">
              <div id="form" className="divFormContact">
                <span>
                  <FontAwesomeIcon icon={faPhone} />
                </span>
                03 87 57 72 10
                <span>
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                crésus.lorraine@orange.fr
              </div>
              <div className="group_label_input_contact_form_first">
                <label
                  htmlFor="standard-select"
                  className="label_contact_form"
                  data-end=" *"
                >
                  Sujet
                </label>
                <div className="select">
                  
                  <select id="standard-select" name="user_subject">
                    <option value="void">-- Entrez un sujet --</option>
                    <option value="gestion">Gestion Budgetaire</option>
                    <option value="surendettement">Surendettement</option>
                    <option value="microCredit">Micro Credit</option>
                    <option value="autre">Autre</option>
                  </select>
                  <span className="focus"></span>
                </div>
              </div>
              <div className="group_label_input_contact_form_0">
                <label className="label_contact_form" data-end=" *">
                  Nom
                </label>
                <br />
                <input type="text" name="user_lastName" required />
              </div>
              <div className="group_label_input_contact_form_1">
                <label className="label_contact_form" data-end=" *">
                  Prénom
                </label>
                <br />
                <input type="text" name="user_firstName" required />
              </div>

              <div className="break_contact_form"></div>

              <div className="group_label_input_contact_form_2">
                <label className="label_contact_form" data-end=" *">
                  Ville
                </label>
                <br />
                <input type="text" name="user_city" required />
              </div>
              <div className="group_label_input_contact_form_3">
                <label className="label_contact_form" data-end=" *">
                  Code Postal
                </label>
                <br />
                <input type="number" name="user_zipCode" required />
              </div>

              <div className="break_contact_form"></div>

              <div className="group_label_input_contact_form_4">
                <label className="label_contact_form" data-end=" *">
                  Email
                </label>
                <br />
                <input type="email" name="user_email" required />
              </div>

              <div className="group_label_input_contact_form_5">
                <label className="label_contact_form" data-end=" *">
                  Téléphone
                </label>
                <br />
                <input type="tel" name="user_phone" required />
              </div>

              <div className="break_contact_form"></div>

              <div className="group_label_input_contact_form_6">
                <label className="label_contact_form" data-end=" *">
                  Message
                </label>
                <br />
                <textarea name="message" required />
              </div>

              <div className="break_contact_form"></div>

              <div className="group_label_input_contact_form_7">
                <input type="checkbox" name="acceptance" required></input>
                <label>
                  En soumettant ce formulaire, j'accepte que les informations
                  saisies soient exploitées pour permettre de me recontacter.
                </label>
              </div>
              <br></br>

              <div className="break_contact_form"></div>
              <br />
              <input
                type="submit"
                value="Envoyer"
                className="submit_contact_form"
              />
            </form>
          </article>
        </div>
      </main>
      <FooterNav />
    </>
  );
}

export default Contact;
