import "./NavBar.css";
import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import cresusLogo from "../assets/logo_cresus.png";

function NavBar(props) {
  const [isActive, setIsActive] = useState(true);
  var val = "popover";

  const handleClick = () => {
    setIsActive((current) => !current);
    val += isActive;
  };

  return (
    <>
      <div className="container">
        <Link to="/">
          <img className="logo_burger_navbar" src={cresusLogo} alt=""></img>
        </Link>
        <a className="a_burger_menu" href="#menu">
          <FontAwesomeIcon icon={faBars} />
        </a>
        <div className={val} id="menu">
          <div className="content">
            {/*eslint-disable-next-line*/}
            <a href="#" className="close"></a>
            <div className="nav">
              <ul className="nav_list">
                <div className="nav_list_item">
                  <li>
                    &nbsp;
                    <Link
                      to="/"
                      className={props.name === "Accueil" ? "button one" : ""}
                    >
                      Accueil&nbsp;
                    </Link>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;
                  </li>
                  <li className="li_2">
                    <a href="/" className="nav_list_sub_item">
                      <font color="#0c86ef">&#9656;</font> Crésus Lorraine
                    </a>
                    <br />
                    <a href="/#cards" className="nav_list_sub_item">
                      <font color="#0c86ef">&#9656;</font> Nos missions
                    </a>
                    <br />
                  </li>
                </div>
                <div className="nav_list_item">
                  <li>
                    &nbsp;
                    <Link
                      to="/aides"
                      className={props.name === "Aides" ? "button one" : ""}
                    >
                      Aides
                    </Link>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;
                  </li>
                  <li className="li_2">
                    <a href="/gestion-budget" className="nav_list_sub_item">
                      <font color="#0c86ef">&#9656;</font> Gestion budgétaire
                    </a>
                    <br />
                    <a href="/surendettement" className="nav_list_sub_item">
                      <font color="#0c86ef">&#9656;</font> Surendettement
                    </a>
                    <br />
                    <a href="/micro-credit" className="nav_list_sub_item">
                      <font color="#0c86ef">&#9656;</font> Micro crédit
                    </a>
                    <br />
                    <a href="/formation" className="nav_list_sub_item">
                      <font color="#0c86ef">&#9656;</font> Formations
                    </a>
                    <br />
                  </li>
                </div>
                <div className="nav_list_item">
                  <li>
                    &nbsp;
                    <Link
                      to="/contact#"
                      className={props.name === "Contact" ? "button one" : ""}
                    >
                      Contact
                    </Link>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;
                  </li>
                  <li className="li_2">
                    <Link
                      to="/contact#map"
                      className="nav_list_sub_item"
                      onClick={handleClick}
                    >
                      <font color="#0c86ef">&#9656;</font> Localisations
                    </Link>
                    <br />
                    <Link
                      to="/contact#form"
                      className="nav_list_sub_item"
                      onClick={handleClick}
                    >
                      <font color="#0c86ef">&#9656;</font> Formulaire
                    </Link>
                    <br />
                  </li>
                </div>
                <div className="nav_list_item">
                  <li>
                    &nbsp;
                    <Link
                      to="/ressources"
                      className={
                        props.name === "Ressources" ? "button one" : ""
                      }
                    >
                      Ressources
                    </Link>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;
                  </li>
                  <li className="li_2">
                    <a href="/ressources#lien-utils" className="nav_list_sub_item">
                      <font color="#0c86ef">&#9656;</font> Liens utiles
                    </a>
                    <br />
                    <a href="/ressources#partenaires" className="nav_list_sub_item">
                      <font color="#0c86ef">&#9656;</font> Partenaires
                    </a>
                    <br />
                    <a href="/a" className="nav_list_sub_item">
                      <font color="#0c86ef">&#9656;</font> Mentions légales
                    </a>
                    <br />
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavBar;
