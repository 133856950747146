import NavBarBig from "./NavBarBig";
import NavBarSmall from "./NavBarSmall";
import { useMediaQuery } from "react-responsive";

function NavBar(props) {
  const isSmall = useMediaQuery({ query: `(max-width: 900px)` });
  
  const navBarDisplay = (isSmall ? <NavBarSmall name={props.name}/> : <NavBarBig />);

  return (
  <>
    {navBarDisplay }
  </>);

}

export default NavBar;
