import React, { useEffect, useState } from "react";
import "../../components/FaqHome.scss";

function FormationFaq() {
  const content = {
    "Qui peut nous solliciter ?":
      "<ol><li>1. les établissements scolaires</li> <li>2. les CCAS,<li>3. les CMS Missions locales</li> <li>4. les centres AFPA <li>5. les associations</li> <li>6. les structures du secteur privé</li></ol>",
    "Quels sont les sujets abordés ?":
      "<ul><li>Les postes budgétaires</li> <li>Les différents types de crédits</li> <li>La consommation</li> <li>Les moyens de paiements</li> <li>…</li> </ul>",
  };

  const [accordions, setAccordion] = useState([]);

  useEffect(() => {
    setAccordion(document.querySelectorAll(".accordion button"));
  }, []);

  const toggleAccordion = (e) => {
    setAccordion(document.querySelectorAll(".accordion button"));

    if (e.getAttribute("aria-expanded") === "false") {
      for (let i = 0; i < accordions.length; i++) {
        accordions[i].setAttribute("aria-expanded", "false");
      }
      e.setAttribute("aria-expanded", "true");
    } else {
      e.setAttribute("aria-expanded", "false");
    }
  };

  const faqFiller = () => {
    let htmlFaq = [];

    let conter = 0;
    for (const property in content) {
      conter++;
      //console.log(`${property}: ${content[property]}`);
      htmlFaq.push (
        <div className="accordion-item" key={conter}>
          <button
            id={`accordion-button-${conter}`}
            aria-expanded="false"
            onClick={(e) => toggleAccordion(e.currentTarget)}
          >
            <span className="accordion-title">{property}</span>
            <span className="icon" aria-hidden="true"></span>
          </button>
          <div className="accordion-content">
            <p dangerouslySetInnerHTML={{ __html: content[property] }}></p>
          </div>
        </div>
      );
    }
    return htmlFaq;
    //return htmlFaq;
  };

  return (
    <div className="container-faq">
      <div className="accordion">
       
          
        {faqFiller()}
      </div>
    </div>
  );
}

export default FormationFaq;
