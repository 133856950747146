import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./StackedCards.sass";

function StackedCards() {
  return (
    <div className="deck">
      <div className="card-stacked">
        <a href="/gestion-budget" className="link-card-stacked">
          <span className="card__link" href="/">
            <header className="cardHeader">
              <span className="cardHeader_account">Conseil budgétaire</span>
              <FontAwesomeIcon icon={faArrowRight} />
            </header>
          </span>
          <div className="cardBody">
            <p className="cardText">
            Vous avez besoin de conseils confidentiels, gratuits et personnalisés sur la gestion de votre budget…
            </p>
          </div>
        </a>
      </div>

      <div className="card-stacked">
        <a href="/surendettement" className="link-card-stacked">
          <span className="card__link" href="/">
            <header className="cardHeader">
              <span className="cardHeader_account">
                Surendettement
              </span>
              <FontAwesomeIcon icon={faArrowRight} />
            </header>
          </span>
          <div className="cardBody">
            <p className="cardText">
            Si vous n'arrivez plus, malgré vos efforts, à payer vos dettes courantes c'est que vous êtes peut être en situation de surendettement…
            </p>
          </div>
        </a>
      </div>

      <div className="card-stacked">
        <a href="/micro-credit" className="link-card-stacked">
          <span className="card__link" href="/">
            <header className="cardHeader">
              <span className="cardHeader_account">Microcrédit</span>
              <FontAwesomeIcon icon={faArrowRight} />
            </header>
          </span>
          <div className="cardBody">
            <p className="cardText">
            Vous voulez financer un projet personnel, et vous n’avez pas accès au crédit bancaire classique, le microcrédit peut être la solution…
            </p>
          </div>
        </a>
      </div>
      <div className="card-stacked">
        <a href="/formation" className="link-card-stacked">
          <span className="card__link" href="/">
            <header className="cardHeader">
              <span className="cardHeader_account">
                Formation
              </span>
              <FontAwesomeIcon icon={faArrowRight} />
            </header>
          </span>
          <div className="cardBody">
            <p className="cardText">
            Vous avez besoin de conseils pour éviter le mal endettement et/ou le surendettement. Des séances collectives d’information vous sont proposées…
            </p>
          </div>
        </a>
      </div>
    </div>
  );
}

export default StackedCards;
