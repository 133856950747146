import NavBar from "../components/NavBar";

function NotFoundPage() {
  return (
    <>
      <NavBar name="NotFound" />
      <br /><br /><br /><br /><br /><br /><br />
      <h1>404 Page non existante</h1>
      <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    </>
  );
}

export default NotFoundPage;
